<template>
  <div class="favorite-button p-2 text-white cursor-pointer" @click="handleClick">
    <svg-icon :name="svgIconName" class="w-6 h-6" :data-testid="svgIconName" />
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/store/auth'
import { useBranchStore } from '@/store/branch'
import { useProductStore } from '@/store/product'

interface Props {
  id: number
  isFavorited: boolean
  type?: 'product' | 'branch'
  saleRegionId?: number
}

const authStore = useAuthStore()
const productStore = useProductStore()
const branchStore = useBranchStore()

const { fetchCollectionApi } = useCollection()
const { prepareForLogin } = useLogin()

const props = withDefaults(defineProps<Props>(), { type: 'product' })

const svgIconName = computed(() => (props.isFavorited ? 'heart' : 'heart-line'))

watch(
  () => authStore.isLogin,
  newIsLogin => {
    if (newIsLogin) return

    const isProductType = props.type === 'product'
    const theStore = isProductType ? productStore : branchStore

    theStore.setValueToMapByKey(props.id, 'is_favorite', false)
  }
)

const handleClick = () => {
  if (!authStore.isLogin) {
    prepareForLogin({ type: 'addFavorite', payload: { id: props.id, type: props.type } })
    authStore.openLoginModal()
    return
  }

  fetchCollectionApi({ id: props.id, type: props.type, isFavorited: props.isFavorited, saleRegionId: props.saleRegionId })
}
</script>

<style lang="scss" scoped></style>
