<template>
  <div class="product-card pb-3 relative bg-gray-10">
    <locale-link :to="`/regions/${regionId}/products/${product.product_id}`">
      <div class="image relative overflow-hidden rounded">
        <nd-img class="block w-full aspect-1.8 object-cover" :src="`fn:${product.cover_image}`" :alt="product.product_name" />
        <div class="overlay absolute top-0 left-0 w-full h-full">
          <div class="tags absolute top-3 left-3 text-xs flex gap-1">
            <div v-for="tag in tags" :key="tag.text" class="tag py-2px px-2 rounded" :class="tag.class">{{ tag.text }}</div>
          </div>
        </div>
      </div>
      <div class="info mt-3">
        <p class="product-card-title text-lg line-clamp-2 h-48px px-2">{{ product.product_name }}</p>
        <span class="merchant text-base mt-2 line-clamp-1 text-gray-70 px-2">{{ product.branch_name }}</span>
        <div class="footer flex justify-between items-center mt-2 pl-1 pr-2">
          <div v-if="showLocation" class="location flex items-center">
            <svg-icon name="location" class="w-6 h-6 mr-1 text-yellow-50" />
            <span class="text-base text-black-50">{{ regionTag }}</span>
          </div>
          <i18n-t class="price text-lg text-black-50 flex items-center gap-1" tag="p" keypath="my_favorites_product_card_price">
            <template #currency>
              <span class>{{ product.currency_code }}</span>
            </template>
            <template #price>
              <span v-price="product.discount" class="font-700"></span>
            </template>
          </i18n-t>
        </div>
      </div>
    </locale-link>
    <favorite-button
      :id="product.product_id!"
      :is-favorited="product?.is_favorite || false"
      :sale-region-id="product?.sale_region_id"
      class="absolute top-0 right-0 z-1"
    />
  </div>
</template>

<script setup lang="ts">
import FavoriteButton from '@/components/common/favorite-button.vue'

import { useProductStore } from '@/store/product'

interface Props {
  productId: number
  productRegionId?: number
  showLocation?: boolean
}

const { t } = useI18n()

const tagsMap = computed(() => ({
  hot: {
    text: t('product_label_popular'),
    class: 'bg-red-50 text-white',
  },
  new: {
    text: t('product_label_new_arrival'),
    class: 'bg-green-60 text-white',
  },
}))

const props = withDefaults(defineProps<Props>(), { showLocation: true })

const productStore = useProductStore()

const { regionId: defaultRegionId } = useRegion()

const regionId = computed(() => props.productRegionId ?? defaultRegionId.value)
const product = computed(() => productStore.productMap[props.productId])
const regionTag = computed(() => product.value.region_tag)
const tags = computed(() => [...(product.value.is_hot ? [tagsMap.value.hot] : []), ...(product.value.is_new_product ? [tagsMap.value.new] : [])])
</script>

<style lang="scss" scoped>
.product-card {
  .overlay {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  }
}
</style>
